import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        soft_name: '森林资源数据通',
        soft_version: 'V1.0.5',
        company_name: 'dingguodong.net',
        copyright_info: '2024',
        server_url: 'https://mini.dingguodong.net',
        user_info: {
            manager_name: '',
            manager_power: '',
            manager_id: '',
            company_id: ''
        },
        menuIsCollapse: true,
        dutiesOptions: [{
            value: '0',
            label: '董事长'
        }, {
            value: '1',
            label: '总经理'
        }, {
            value: '2',
            label: '副总经理'
        }, {
            value: '3',
            label: '财务总监'
        }, {
            value: '4',
            label: '秘书长'
        }, {
            value: '5',
            label: '部长'
        }, {
            value: '6',
            label: '副部长'
        }, {
            value: '7',
            label: '主管'
        }, {
            value: '8',
            label: '科员'
        }],
        companyOptions: [{
            value: '0',
            label: '集团'
        }, {
            value: '1',
            label: '汪清林业局'
        }, {
            value: '2',
            label: '白河林业局'
        }, {
            value: '3',
            label: '黄泥河林业局'
        }, {
            value: '4',
            label: '敦化林业局'
        }, {
            value: '5',
            label: '大石头林业局'
        }, {
            value: '6',
            label: '八家子林业局'
        }, {
            value: '7',
            label: '和龙林业局'
        }, {
            value: '8',
            label: '天桥岭林业局'
        }, {
            value: '9',
            label: '大兴沟林业局'
        }, {
            value: '10',
            label: '珲春林业局'
        }, {
            value: '11',
            label: '安图森林经营局'
        }, {
            value: '12',
            label: '白石山林业局'
        }, {
            value: '13',
            label: '红石林业局'
        }, {
            value: '14',
            label: '露水河林业局'
        }, {
            value: '15',
            label: '泉阳林业局'
        }, {
            value: '16',
            label: '松江河林业局'
        }, {
            value: '17',
            label: '湾沟林业局'
        }, {
            value: '18',
            label: '三岔子林业局'
        }, {
            value: '19',
            label: '临江林业局'
        }],
        companysOptions: [{
                "value": "全集团",
                "label": "全集团",
            },
            {
                "value": "吉林森工",
                "label": "吉林森工",
                "children": [{
                        "value": "三岔子林业局",
                        "label": "三岔子林业局",
                        "children": [{
                                "value": "三岔子林场",
                                "label": "三岔子林场"
                            },
                            {
                                "value": "三道湖林场",
                                "label": "三道湖林场"
                            },
                            {
                                "value": "新胜林场",
                                "label": "新胜林场"
                            },
                            {
                                "value": "景山林场",
                                "label": "景山林场"
                            },
                            {
                                "value": "白江河林场",
                                "label": "白江河林场"
                            },
                            {
                                "value": "那尔轰林场",
                                "label": "那尔轰林场"
                            },
                            {
                                "value": "龙湾林场",
                                "label": "龙湾林场"
                            }
                        ]
                    },
                    {
                        "value": "临江林业局",
                        "label": "临江林业局",
                        "children": [{
                                "value": "东小山林场",
                                "label": "东小山林场"
                            },
                            {
                                "value": "大西林场",
                                "label": "大西林场"
                            },
                            {
                                "value": "柳树河林场",
                                "label": "柳树河林场"
                            },
                            {
                                "value": "柳毛河林场",
                                "label": "柳毛河林场"
                            },
                            {
                                "value": "桦树林场",
                                "label": "桦树林场"
                            },
                            {
                                "value": "红土山经营所",
                                "label": "红土山经营所"
                            },
                            {
                                "value": "芒河经营所",
                                "label": "芒河经营所"
                            },
                            {
                                "value": "西小山林场",
                                "label": "西小山林场"
                            },
                            {
                                "value": "贾家营林场",
                                "label": "贾家营林场"
                            },
                            {
                                "value": "金山林场",
                                "label": "金山林场"
                            },
                            {
                                "value": "银山经营所",
                                "label": "银山经营所"
                            },
                            {
                                "value": "闹枝林场",
                                "label": "闹枝林场"
                            }
                        ]
                    },
                    {
                        "value": "松江河林业局",
                        "label": "松江河林业局",
                        "children": [{
                                "value": "前川林场",
                                "label": "前川林场"
                            },
                            {
                                "value": "开峰林场",
                                "label": "开峰林场"
                            },
                            {
                                "value": "抚南林场",
                                "label": "抚南林场"
                            },
                            {
                                "value": "曙光林场",
                                "label": "曙光林场"
                            },
                            {
                                "value": "松山林场",
                                "label": "松山林场"
                            },
                            {
                                "value": "板石河林场",
                                "label": "板石河林场"
                            },
                            {
                                "value": "槽子河林场",
                                "label": "槽子河林场"
                            },
                            {
                                "value": "漫江林场",
                                "label": "漫江林场"
                            },
                            {
                                "value": "白西林场",
                                "label": "白西林场"
                            },
                            {
                                "value": "老岭林场",
                                "label": "老岭林场"
                            },
                            {
                                "value": "胜利林场",
                                "label": "胜利林场"
                            },
                            {
                                "value": "锦北林场",
                                "label": "锦北林场"
                            },
                            {
                                "value": "青川林场",
                                "label": "青川林场"
                            },
                            {
                                "value": "马鞍山林场",
                                "label": "马鞍山林场"
                            },
                            {
                                "value": "黑河林场",
                                "label": "黑河林场"
                            }
                        ]
                    },
                    {
                        "value": "泉阳林业局",
                        "label": "泉阳林业局",
                        "children": [{
                                "value": "东北岔",
                                "label": "东北岔"
                            },
                            {
                                "value": "兴参",
                                "label": "兴参"
                            },
                            {
                                "value": "北岗",
                                "label": "北岗"
                            },
                            {
                                "value": "大东",
                                "label": "大东"
                            },
                            {
                                "value": "大川",
                                "label": "大川"
                            },
                            {
                                "value": "大顶子",
                                "label": "大顶子"
                            },
                            {
                                "value": "抚安",
                                "label": "抚安"
                            },
                            {
                                "value": "明水",
                                "label": "明水"
                            },
                            {
                                "value": "榆树",
                                "label": "榆树"
                            },
                            {
                                "value": "泉水",
                                "label": "泉水"
                            },
                            {
                                "value": "胜利",
                                "label": "胜利"
                            }
                        ]
                    },
                    {
                        "value": "湾沟林业局",
                        "label": "湾沟林业局",
                        "children": [{
                                "value": "三道花园林场",
                                "label": "三道花园林场"
                            },
                            {
                                "value": "二道花园林场",
                                "label": "二道花园林场"
                            },
                            {
                                "value": "仙人洞林场",
                                "label": "仙人洞林场"
                            },
                            {
                                "value": "四平林场",
                                "label": "四平林场"
                            },
                            {
                                "value": "大安林场",
                                "label": "大安林场"
                            },
                            {
                                "value": "大营林场",
                                "label": "大营林场"
                            },
                            {
                                "value": "夹皮沟林场",
                                "label": "夹皮沟林场"
                            },
                            {
                                "value": "松树经营所",
                                "label": "松树经营所"
                            },
                            {
                                "value": "榆树川经营所",
                                "label": "榆树川经营所"
                            },
                            {
                                "value": "湾沟林场",
                                "label": "湾沟林场"
                            },
                            {
                                "value": "马鹿沟林场",
                                "label": "马鹿沟林场"
                            }
                        ]
                    },
                    {
                        "value": "白石山林业局",
                        "label": "白石山林业局",
                        "children": [{
                                "value": "双山林场",
                                "label": "双山林场"
                            },
                            {
                                "value": "大石河林场",
                                "label": "大石河林场"
                            },
                            {
                                "value": "大趟子林场",
                                "label": "大趟子林场"
                            },
                            {
                                "value": "漂河林场",
                                "label": "漂河林场"
                            },
                            {
                                "value": "琵河林场",
                                "label": "琵河林场"
                            },
                            {
                                "value": "白石山林场",
                                "label": "白石山林场"
                            },
                            {
                                "value": "胜利河林场",
                                "label": "胜利河林场"
                            },
                            {
                                "value": "黄松甸林场",
                                "label": "黄松甸林场"
                            }
                        ]
                    },
                    {
                        "value": "红石林业局",
                        "label": "红石林业局",
                        "children": [{
                                "value": "东兴林场",
                                "label": "东兴林场"
                            },
                            {
                                "value": "二道沟林场",
                                "label": "二道沟林场"
                            },
                            {
                                "value": "帽山林场",
                                "label": "帽山林场"
                            },
                            {
                                "value": "批洲林场",
                                "label": "批洲林场"
                            },
                            {
                                "value": "板庙子林场",
                                "label": "板庙子林场"
                            },
                            {
                                "value": "桦树林场",
                                "label": "桦树林场"
                            },
                            {
                                "value": "桦树经营所",
                                "label": "桦树经营所"
                            },
                            {
                                "value": "梨树林场",
                                "label": "梨树林场"
                            },
                            {
                                "value": "梨树经营所",
                                "label": "梨树经营所"
                            },
                            {
                                "value": "红石林场",
                                "label": "红石林场"
                            },
                            {
                                "value": "老岭林场",
                                "label": "老岭林场"
                            },
                            {
                                "value": "老岭经营所",
                                "label": "老岭经营所"
                            },
                            {
                                "value": "黄泥河林场",
                                "label": "黄泥河林场"
                            }
                        ]
                    },
                    {
                        "value": "露水河林业局",
                        "label": "露水河林业局",
                        "children": [{
                                "value": "东升林场",
                                "label": "东升林场"
                            },
                            {
                                "value": "四湖林场",
                                "label": "四湖林场"
                            },
                            {
                                "value": "新兴林场",
                                "label": "新兴林场"
                            },
                            {
                                "value": "永青林场",
                                "label": "永青林场"
                            },
                            {
                                "value": "清水河林场",
                                "label": "清水河林场"
                            },
                            {
                                "value": "红光林场",
                                "label": "红光林场"
                            },
                            {
                                "value": "西林河林场",
                                "label": "西林河林场"
                            },
                            {
                                "value": "黎明林场",
                                "label": "黎明林场"
                            }
                        ]
                    }
                ]
            },
            {
                "value": "长白山森工",
                "label": "长白山森工",
                "children": [{
                        "value": "八家子林业局",
                        "label": "八家子林业局",
                        "children": [{
                                "value": "三道林场",
                                "label": "三道林场"
                            },
                            {
                                "value": "仲乡林场",
                                "label": "仲乡林场"
                            },
                            {
                                "value": "先锋林场",
                                "label": "先锋林场"
                            },
                            {
                                "value": "升平林场",
                                "label": "升平林场"
                            },
                            {
                                "value": "古洞河林场",
                                "label": "古洞河林场"
                            },
                            {
                                "value": "安北林场",
                                "label": "安北林场"
                            },
                            {
                                "value": "官地林场",
                                "label": "官地林场"
                            },
                            {
                                "value": "庙岭林场",
                                "label": "庙岭林场"
                            },
                            {
                                "value": "泉水洞林场",
                                "label": "泉水洞林场"
                            },
                            {
                                "value": "腰团林场",
                                "label": "腰团林场"
                            },
                            {
                                "value": "荒沟林场",
                                "label": "荒沟林场"
                            },
                            {
                                "value": "马鞍山林场",
                                "label": "马鞍山林场"
                            }
                        ]
                    },
                    {
                        "value": "和龙林业局",
                        "label": "和龙林业局",
                        "children": [{
                                "value": "东树沟林",
                                "label": "东树沟林"
                            },
                            {
                                "value": "古城林场",
                                "label": "古城林场"
                            },
                            {
                                "value": "大满沟林",
                                "label": "大满沟林"
                            },
                            {
                                "value": "局址",
                                "label": "局址"
                            },
                            {
                                "value": "广坪林场",
                                "label": "广坪林场"
                            },
                            {
                                "value": "星火林场",
                                "label": "星火林场"
                            },
                            {
                                "value": "石人沟林",
                                "label": "石人沟林"
                            },
                            {
                                "value": "红旗河林",
                                "label": "红旗河林"
                            },
                            {
                                "value": "花砬子林",
                                "label": "花砬子林"
                            },
                            {
                                "value": "荒沟林场",
                                "label": "荒沟林场"
                            },
                            {
                                "value": "许家洞林",
                                "label": "许家洞林"
                            },
                            {
                                "value": "长森岭林",
                                "label": "长森岭林"
                            },
                            {
                                "value": "长红林场",
                                "label": "长红林场"
                            },
                            {
                                "value": "马鹿沟林",
                                "label": "马鹿沟林"
                            }
                        ]
                    },
                    {
                        "value": "大兴沟林业局",
                        "label": "大兴沟林业局",
                        "children": [{
                                "value": "北青沟林场",
                                "label": "北青沟林场"
                            },
                            {
                                "value": "周仁沟林场",
                                "label": "周仁沟林场"
                            },
                            {
                                "value": "大兴沟林场",
                                "label": "大兴沟林场"
                            },
                            {
                                "value": "大石林场",
                                "label": "大石林场"
                            },
                            {
                                "value": "小东沟林场",
                                "label": "小东沟林场"
                            },
                            {
                                "value": "岭东林场",
                                "label": "岭东林场"
                            },
                            {
                                "value": "影壁林场",
                                "label": "影壁林场"
                            },
                            {
                                "value": "托盘林场",
                                "label": "托盘林场"
                            },
                            {
                                "value": "柳亭林场",
                                "label": "柳亭林场"
                            },
                            {
                                "value": "档树河林场",
                                "label": "档树河林场"
                            },
                            {
                                "value": "红石林场",
                                "label": "红石林场"
                            }
                        ]
                    },
                    {
                        "value": "大石头林业局",
                        "label": "大石头林业局",
                        "children": [{
                                "value": "万宝林场",
                                "label": "万宝林场"
                            },
                            {
                                "value": "东明林场",
                                "label": "东明林场"
                            },
                            {
                                "value": "东石河林场",
                                "label": "东石河林场"
                            },
                            {
                                "value": "刘生店林场",
                                "label": "刘生店林场"
                            },
                            {
                                "value": "和平林场",
                                "label": "和平林场"
                            },
                            {
                                "value": "大石头林场",
                                "label": "大石头林场"
                            },
                            {
                                "value": "大荒沟林场",
                                "label": "大荒沟林场"
                            },
                            {
                                "value": "小荒沟林场",
                                "label": "小荒沟林场"
                            },
                            {
                                "value": "新合林场",
                                "label": "新合林场"
                            },
                            {
                                "value": "沙河源林场",
                                "label": "沙河源林场"
                            },
                            {
                                "value": "沟口林场",
                                "label": "沟口林场"
                            },
                            {
                                "value": "西北岔林场",
                                "label": "西北岔林场"
                            },
                            {
                                "value": "青林林场",
                                "label": "青林林场"
                            },
                            {
                                "value": "鱼亮子林场",
                                "label": "鱼亮子林场"
                            },
                            {
                                "value": "黎明林场",
                                "label": "黎明林场"
                            }
                        ]
                    },
                    {
                        "value": "天桥岭林业局",
                        "label": "天桥岭林业局",
                        "children": [{
                                "value": "上河林场",
                                "label": "上河林场"
                            },
                            {
                                "value": "八人沟林场",
                                "label": "八人沟林场"
                            },
                            {
                                "value": "内河林场",
                                "label": "内河林场"
                            },
                            {
                                "value": "向阳林场",
                                "label": "向阳林场"
                            },
                            {
                                "value": "响水林场",
                                "label": "响水林场"
                            },
                            {
                                "value": "地区",
                                "label": "地区"
                            },
                            {
                                "value": "大安林场",
                                "label": "大安林场"
                            },
                            {
                                "value": "太阳林场",
                                "label": "太阳林场"
                            },
                            {
                                "value": "张家店林场",
                                "label": "张家店林场"
                            },
                            {
                                "value": "新华林场",
                                "label": "新华林场"
                            },
                            {
                                "value": "新开林场",
                                "label": "新开林场"
                            },
                            {
                                "value": "桦皮林场",
                                "label": "桦皮林场"
                            },
                            {
                                "value": "桶子沟林场",
                                "label": "桶子沟林场"
                            },
                            {
                                "value": "白石林场",
                                "label": "白石林场"
                            },
                            {
                                "value": "葡萄沟林场",
                                "label": "葡萄沟林场"
                            },
                            {
                                "value": "西大河林场",
                                "label": "西大河林场"
                            }
                        ]
                    },
                    {
                        "value": "安图森林经营局",
                        "label": "安图森林经营局",
                        "children": [{
                                "value": "三道林场",
                                "label": "三道林场"
                            },
                            {
                                "value": "东明林场",
                                "label": "东明林场"
                            },
                            {
                                "value": "东沟林场",
                                "label": "东沟林场"
                            },
                            {
                                "value": "屯田林场",
                                "label": "屯田林场"
                            },
                            {
                                "value": "岛安林场",
                                "label": "岛安林场"
                            },
                            {
                                "value": "梨树林场",
                                "label": "梨树林场"
                            },
                            {
                                "value": "长兴林场",
                                "label": "长兴林场"
                            }
                        ]
                    },
                    {
                        "value": "敦化林业局",
                        "label": "敦化林业局",
                        "children": [{
                                "value": "三道沟林场",
                                "label": "三道沟林场"
                            },
                            {
                                "value": "二道沟林场",
                                "label": "二道沟林场"
                            },
                            {
                                "value": "二龙山林场",
                                "label": "二龙山林场"
                            },
                            {
                                "value": "仁义河林场",
                                "label": "仁义河林场"
                            },
                            {
                                "value": "保中桥林场",
                                "label": "保中桥林场"
                            },
                            {
                                "value": "光明林场",
                                "label": "光明林场"
                            },
                            {
                                "value": "和平林场",
                                "label": "和平林场"
                            },
                            {
                                "value": "大沟林场",
                                "label": "大沟林场"
                            },
                            {
                                "value": "大石河林场",
                                "label": "大石河林场"
                            },
                            {
                                "value": "大蒲柴河林场",
                                "label": "大蒲柴河林场"
                            },
                            {
                                "value": "小沟林场",
                                "label": "小沟林场"
                            },
                            {
                                "value": "小蒲柴河林场",
                                "label": "小蒲柴河林场"
                            },
                            {
                                "value": "建设林场",
                                "label": "建设林场"
                            },
                            {
                                "value": "松江林场",
                                "label": "松江林场"
                            },
                            {
                                "value": "柳树河林场",
                                "label": "柳树河林场"
                            },
                            {
                                "value": "沙河沿林场",
                                "label": "沙河沿林场"
                            },
                            {
                                "value": "浪柴河林场",
                                "label": "浪柴河林场"
                            },
                            {
                                "value": "石门子林场",
                                "label": "石门子林场"
                            },
                            {
                                "value": "荒沟林场",
                                "label": "荒沟林场"
                            },
                            {
                                "value": "莲花泡林场",
                                "label": "莲花泡林场"
                            }
                        ]
                    },
                    {
                        "value": "汪清林业局",
                        "label": "汪清林业局",
                        "children": [{
                                "value": "六道林场",
                                "label": "六道林场"
                            },
                            {
                                "value": "兰家林场",
                                "label": "兰家林场"
                            },
                            {
                                "value": "地阴沟林场",
                                "label": "地阴沟林场"
                            },
                            {
                                "value": "塔子沟林场",
                                "label": "塔子沟林场"
                            },
                            {
                                "value": "大柞树林场",
                                "label": "大柞树林场"
                            },
                            {
                                "value": "大荒沟林场",
                                "label": "大荒沟林场"
                            },
                            {
                                "value": "局址",
                                "label": "局址"
                            },
                            {
                                "value": "杜荒子林场",
                                "label": "杜荒子林场"
                            },
                            {
                                "value": "沙金沟林场",
                                "label": "沙金沟林场"
                            },
                            {
                                "value": "浪溪林场",
                                "label": "浪溪林场"
                            },
                            {
                                "value": "珲春市",
                                "label": "珲春市"
                            },
                            {
                                "value": "荒沟林场",
                                "label": "荒沟林场"
                            },
                            {
                                "value": "西南岔林场",
                                "label": "西南岔林场"
                            },
                            {
                                "value": "金沟岭林场",
                                "label": "金沟岭林场"
                            },
                            {
                                "value": "金苍林场",
                                "label": "金苍林场"
                            }
                        ]
                    },
                    {
                        "value": "珲春林业局",
                        "label": "珲春林业局",
                        "children": [{
                                "value": "三道沟经营区",
                                "label": "三道沟经营区"
                            },
                            {
                                "value": "五道沟经营区",
                                "label": "五道沟经营区"
                            },
                            {
                                "value": "兰家趟子经营区",
                                "label": "兰家趟子经营区"
                            },
                            {
                                "value": "凉水林场",
                                "label": "凉水林场"
                            },
                            {
                                "value": "合作区委托",
                                "label": "合作区委托"
                            },
                            {
                                "value": "大荒沟林场",
                                "label": "大荒沟林场"
                            },
                            {
                                "value": "敬信委托",
                                "label": "敬信委托"
                            },
                            {
                                "value": "敬信林场",
                                "label": "敬信林场"
                            },
                            {
                                "value": "春化经营区",
                                "label": "春化经营区"
                            },
                            {
                                "value": "杨泡委托",
                                "label": "杨泡委托"
                            },
                            {
                                "value": "杨泡营林站",
                                "label": "杨泡营林站"
                            },
                            {
                                "value": "板石委托",
                                "label": "板石委托"
                            },
                            {
                                "value": "板石营林站",
                                "label": "板石营林站"
                            },
                            {
                                "value": "河山林场",
                                "label": "河山林场"
                            },
                            {
                                "value": "英安林场",
                                "label": "英安林场"
                            },
                            {
                                "value": "西北沟经营区",
                                "label": "西北沟经营区"
                            },
                            {
                                "value": "解放林场",
                                "label": "解放林场"
                            },
                            {
                                "value": "金泉岗经营区",
                                "label": "金泉岗经营区"
                            },
                            {
                                "value": "青龙台林场",
                                "label": "青龙台林场"
                            },
                            {
                                "value": "马滴达经营区",
                                "label": "马滴达经营区"
                            }
                        ]
                    },
                    {
                        "value": "白河林业局",
                        "label": "白河林业局",
                        "children": [{
                                "value": "东方红林场",
                                "label": "东方红林场"
                            },
                            {
                                "value": "两江林场",
                                "label": "两江林场"
                            },
                            {
                                "value": "二道林场",
                                "label": "二道林场"
                            },
                            {
                                "value": "光明林场",
                                "label": "光明林场"
                            },
                            {
                                "value": "兴隆林场",
                                "label": "兴隆林场"
                            },
                            {
                                "value": "劲松林场",
                                "label": "劲松林场"
                            },
                            {
                                "value": "宝马林场",
                                "label": "宝马林场"
                            },
                            {
                                "value": "春雷林场",
                                "label": "春雷林场"
                            },
                            {
                                "value": "红石林场",
                                "label": "红石林场"
                            },
                            {
                                "value": "黄松蒲林场",
                                "label": "黄松蒲林场"
                            }
                        ]
                    },
                    {
                        "value": "黄泥河林业局",
                        "label": "黄泥河林业局",
                        "children": [{
                                "value": "上马厂",
                                "label": "上马厂"
                            },
                            {
                                "value": "北大秧",
                                "label": "北大秧"
                            },
                            {
                                "value": "团北",
                                "label": "团北"
                            },
                            {
                                "value": "团山子",
                                "label": "团山子"
                            },
                            {
                                "value": "塔拉站",
                                "label": "塔拉站"
                            },
                            {
                                "value": "大川",
                                "label": "大川"
                            },
                            {
                                "value": "威虎岭",
                                "label": "威虎岭"
                            },
                            {
                                "value": "威虎河",
                                "label": "威虎河"
                            },
                            {
                                "value": "小白",
                                "label": "小白"
                            },
                            {
                                "value": "意气松",
                                "label": "意气松"
                            },
                            {
                                "value": "桦皮",
                                "label": "桦皮"
                            },
                            {
                                "value": "秃顶子",
                                "label": "秃顶子"
                            },
                            {
                                "value": "西北岔",
                                "label": "西北岔"
                            },
                            {
                                "value": "都陵",
                                "label": "都陵"
                            },
                            {
                                "value": "金沟",
                                "label": "金沟"
                            },
                            {
                                "value": "青沟子",
                                "label": "青沟子"
                            },
                            {
                                "value": "额穆",
                                "label": "额穆"
                            },
                            {
                                "value": "马鹿沟",
                                "label": "马鹿沟"
                            }
                        ]
                    }
                ]
            }
        ],
        QueryOptions: [{
                "value": "全集团",
                "label": "全集团",
            },
            {
                "value": "吉林森工",
                "label": "吉林森工",
                "children": [{
                    "value": "三岔子林业局",
                    "label": "三岔子林业局"
                }, {
                    "value": "临江林业局",
                    "label": "临江林业局"
                }, {
                    "value": "松江河林业局",
                    "label": "松江河林业局"
                }, {
                    "value": "泉阳林业局",
                    "label": "泉阳林业局"
                }, {
                    "value": "湾沟林业局",
                    "label": "湾沟林业局"
                }, {
                    "value": "白石山林业局",
                    "label": "白石山林业局"
                }, {
                    "value": "红石林业局",
                    "label": "红石林业局"
                }, {
                    "value": "露水河林业局",
                    "label": "露水河林业局"
                }]
            },
            {
                "value": "长白山森工",
                "label": "长白山森工",
                "children": [{
                    "value": "八家子林业局",
                    "label": "八家子林业局"
                }, {
                    "value": "和龙林业局",
                    "label": "和龙林业局"
                }, {
                    "value": "大兴沟林业局",
                    "label": "大兴沟林业局"
                }, {
                    "value": "大石头林业局",
                    "label": "大石头林业局"
                }, {
                    "value": "天桥岭林业局",
                    "label": "天桥岭林业局"
                }, {
                    "value": "安图森林经营局",
                    "label": "安图森林经营局"
                }, {
                    "value": "敦化林业局",
                    "label": "敦化林业局"
                }, {
                    "value": "汪清林业局",
                    "label": "汪清林业局"
                }, {
                    "value": "珲春林业局",
                    "label": "珲春林业局"
                }, {
                    "value": "白河林业局",
                    "label": "白河林业局"
                }, {
                    "value": "黄泥河林业局",
                    "label": "黄泥河林业局"
                }]
            }
        ],
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})