import axios from 'axios'
import router from './router'
import ElementUI from 'element-ui';

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('JWT_TOKEN')) {
            config.headers.common['token'] = localStorage.getItem('JWT_TOKEN')
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log(error)
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('USER_ID')
                    localStorage.removeItem('USER_MANE')
                    localStorage.removeItem('JWT_TOKEN')
                    localStorage.removeItem('USER_PHONE')
                    localStorage.removeItem('USER_COMPANY')
                    localStorage.removeItem('USER_DUTIES')
                    router.replace({
                        path: '/login',
                        query: { redirect: router.currentRoute.fullPath }
                    })
            }
        }
        return Promise.reject(error.response.data) // 返回接口返回的错误信息
    });
export default axios