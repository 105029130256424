<template>
    <el-container>
        <router-view />
    </el-container>
</template>
<script>
// @ is an alias to /src
// import TopMenu from '@/components/menu/TopMenu.vue'

export default {
    name: 'HomeView',
    components: {
        // TopMenu,
    },
    mounted() {
        // console.log(this.$route.meta.name)
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dgd-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>